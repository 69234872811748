<template>
    <div class="echart-main">
        <van-nav-bar title="各科分数排名" left-text="返回" left-arrow @click-left="onClickLeft" />
        <div v-if="chineseScore.length > 0">
            <span style="font-size:22px;">语文</span>
            <hr />
            <ChartLine :score="chineseScore" :rank="chineseRank" style="height:420px;width:100%"></ChartLine>
        </div>
        <div v-if="mathsScore.length > 0">
            <span style="font-size:22px;">数学</span>
            <hr />
            <ChartLine :score="mathsScore" :rank="mathsRank" style="height:420px;width:100%"></ChartLine>
        </div>
        <div v-if="englishScore.length > 0">
            <span style="font-size:22px;">英语</span>
            <hr />
            <ChartLine :score="englishScore" :rank="englishRank" style="height:420px;width:100%"></ChartLine>
        </div>
        <div v-if="daofaScore.length > 0">
            <span style="font-size:22px;">道法</span>
            <ChartLine :score="daofaScore" :rank="daofaRank" style="height:420px;width:100%"></ChartLine>
            <hr />
        </div>
        <div v-if="historyScore.length > 0">
            <span style="font-size:22px;">历史</span>
            <ChartLine :score="historyScore" :rank="historyRank" style="height:420px;width:100%"></ChartLine>
            <hr />
        </div>
        <div v-if="yswScore.length > 0">
            <span style="font-size:22px;">语数外总分</span>
            <ChartLine :score="yswScore" :rank="yswRank" style="height:420px;width:100%"></ChartLine>
            <hr />
        </div>
        <div v-if="totalScore.length > 0">
            <span style="font-size:22px;">总分</span>
            <ChartLine :score="totalScore" :rank="totalRank" style="height:420px;width:100%"></ChartLine>
            <hr />
        </div>
    </div>
</template>

<script>
import { ChartLine } from '@/components/charts/'
import { examScoreRank } from '@/api/exam.api.js'
export default {
    name: 'hwkEchart',
    components: {
        ChartLine
    },
    data() {
        return {
            chineseScore: [], //语文得分
            chineseRank: [], //排名
            englishScore: [],
            englishRank: [],
            mathsScore: [],
            mathsRank: [],
            yswScore: [],
            yswRank: [],
            daofaScore: [],
            daofaRank: [],
            totalScore: [],
            totalRank: [],
            historyScore: [],
            historyRank: [],
            myChart: {}
        }
    },
    methods: {
        onClickLeft() {
            history.back()
        },
        getScoreRank() {
            examScoreRank()
                .then(res => {
                    res.forEach(v => {
                        if (v.examSubject == '数学') {
                            this.mathsScore.push(v.examScore)
                            this.mathsRank.push(v.examRank)
                        }
                        if (v.examSubject == '英语') {
                            this.englishScore.push(v.examScore)
                            this.englishRank.push(v.examRank)
                        }
                        if (v.examSubject == '语文') {
                            this.chineseScore.push(v.examScore)
                            this.chineseRank.push(v.examRank)
                        }
                        if (v.examSubject == '语数外') {
                            this.yswScore.push(v.examScore)
                            this.yswRank.push(v.examRank)
                        }
                        if (v.examSubject == '道法') {
                            this.daofaScore.push(v.examScore)
                            this.daofaRank.push(v.examRank)
                        }
                        if (v.examSubject == '总分') {
                            this.totalfaScore.push(v.examScore)
                            this.totalRank.push(v.examRank)
                        }
                        if (v.examSubject == '历史') {
                            this.historyScore.push(v.examScore)
                            this.historyRank.push(v.examRank)
                        }
                    })
                    console.log(this.chineseScore, '2333333333')
                })
                .catch(err => {
                    console.log(err, 'dddddddddddd')
                })
        }
    },
    mounted() {},
    created() {
        this.getScoreRank()
    }
}
</script>
<style lang="less" scoped>
.echart-main {
    width: 100%;
}
</style>
