<template>
    <div ref="dom" class="charts"></div>
</template>

<script>
var echarts = require('echarts')
import tdTheme from './theme.json'
import { on, off } from '@/utils/tools'
echarts.registerTheme('tdTheme', tdTheme)
export default {
    name: 'ChartBar',
    props: ['score', 'rank'],
    data() {
        return {
            categoryData: [],
            dom: null
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        getCategoryData(ar) {
            let tmp = ''
            ar.forEach((v, i) => {
                tmp += i + ','
                console.log(v, ':', i)
            })
            this.categoryData = tmp.split(',')
        },
        randNumber(type) {
            if (type == '分数') {
                this.getCategoryData(this.score)
                return this.score
            }
            if (type == '排名') {
                return this.rank
            }
        },
        init() {
            this.$nextTick(() => {
                let types = ['分数', '排名']
                let seriesData = types.map(v => {
                    return {
                        name: v,
                        data: this.randNumber(v),
                        type: 'line',
                        symbol: 'none',
                        smooth: true,
                        areaStyle: {
                            opacity: 0.05
                        },
                        emphasis: {
                            focus: 'series'
                        }
                    }
                })
                let option = {
                    title: {
                        text: '概况',
                        left: '15px',
                        top: '15px'
                    },
                    legend: {
                        top: '20px',
                        type: 'plain',
                        data: types
                    },
                    toolbox: {
                        show: true,
                        top: '20px',
                        right: '20px',
                        feature: {
                            magicType: {
                                type: ['line', 'bar']
                            }
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross'
                        },
                        formatter: function(e) {
                            let res = ''
                            res += '<div>' + e[0].name + '</div>'
                            e.forEach((v, i) => {
                                res += "<div class='flex'>"
                                res += v.marker + "<small style='padding-right:10px;'>" + types[i] + ':</small>' + v.data
                                if (i < 5) {
                                    res += '<small></small>'
                                } else {
                                    res += '%'
                                }
                                res += '</div>'
                            })
                            return res
                        }
                    },
                    grid: {
                        left: '5%',
                        right: '5%',
                        bottom: '5%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: this.categoryData
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: seriesData
                }
                this.dom = echarts.init(this.$refs.dom, 'tdTheme')
                this.dom.setOption(option)
                on(window, 'resize', this.resize)
            })
        },
        resize() {
            this.dom.resize()
        }
    },
    beforeDestroy() {
        off(window, 'resize', this.resize)
    }
}
</script>
